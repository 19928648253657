<template>
  <div id="table-imports">
    <div class="imports-header">
      <div class="file-column">
        Fichiers importés
      </div>

      <div class="status-column">
        Statuts
      </div>
    </div>
    
    <div
      v-for="importFile in imports"
      :key="importFile.created_on"
      class="filerow"
    >
      <div class="file-column">
        <h4 class="ui header align-right">
          <div
            :data-tooltip="importFile.geojson_file_name"
            class="ellipsis"
          >
            {{ importFile.geojson_file_name }}
          </div>
        </h4>
        <div class="sub header">
          ajouté le {{ importFile.created_on | formatDate }}
        </div>
      </div>
      
      <div class="status-column">
        <span
          v-if="importFile.infos"
          :data-tooltip="importFile.infos"
          class="ui icon margin-left"
        >
          <i
            v-if="importFile.status === 'processing'"
            class="orange hourglass half icon"
            aria-hidden="true"
          />
          <i
            v-else-if="importFile.status === 'finished'"
            class="green check circle outline icon"
            aria-hidden="true"
          />
          <i
            v-else-if="importFile.status === 'failed'"
            class="red x icon"
            aria-hidden="true"
          />
          <i
            v-else
            class="red ban icon"
            aria-hidden="true"
          />
        </span>
        <span
          v-if="importFile.status === 'pending'"
          data-tooltip="Statut en attente. Cliquez pour rafraichir."
        >
          <i
            :class="['orange icon', !reloading ? 'sync' : 'hourglass half rotate']"
            aria-hidden="true"
            @click="fetchImports()"
          />
        </span>
      </div>
    </div>
  </div>
</template>


<script>

export default {
  filters: {
    formatDate: function (value) {
      const date = new Date(value);
      return date.toLocaleDateString('fr', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });
    },
    subString: function (value) {
      return value.substring(0, 27) + '..';
    },
  },

  props: {
    imports: {
      type: Array,
      default: null,
    },
  },

  data() {
    return {
      reloading: false,
      fetchCallCounter: 0,
    };
  },

  mounted() {
    this.fetchImports();
  },

  methods: {
    fetchImports() {
      this.fetchCallCounter += 1; // register each time function is programmed to be called in order to avoid redundant calls
      this.reloading = true;
      // fetch imports
      this.$store.dispatch('feature-type/GET_IMPORTS', {
        project_slug:  this.$route.params.slug,
        feature_type: this.$route.params.feature_type_slug
      })
        .then((response) => {
          if (response.data && response.data.some(el => el.status === 'pending')) {
            // if there is still some pending imports re-fetch imports by calling this function again
            setTimeout(() => {
              if (this.fetchCallCounter <= 1 ) {
                // if the function wasn't called more than once in the reload interval, then call it again
                this.fetchImports();
              }                
              this.fetchCallCounter -= 1; // decrease function counter
            }, this.$store.state.configuration.VUE_APP_RELOAD_INTERVAL);
            // give a bit time for loader to be seen by user if response was fast
            setTimeout(() => {
              this.reloading = false;
            }, 1500);
          } else {
            // if no pending import, get last features
            this.$emit('reloadFeatureType');
          }
        });
    },
  },
};
</script>

<style scoped lang="less">

#table-imports {
  border: 1px solid lightgrey;
  margin-top: 1rem;
  .imports-header {
    border-bottom: 1px solid lightgrey;
    font-weight: bold;
  }
  > div {
    padding: .5em 1em;
  }
  .filerow {
    background-color: #fff;
  }
  .imports-header, .filerow {
    display: flex;

    .file-column {
      width: 80%;
      h4 {
        margin-bottom: .2em;
      }
    }
    .status-column {
      width: 20%;
      text-align: right;
    }
  }
}

.sync {
  cursor: pointer;
}

i.icon {
  width: 20px !important;
  height: 20px !important;
}

.rotate {
  -webkit-animation:spin 1.5s cubic-bezier(.3,.25,.15,1) infinite;
  -moz-animation:spin 1.5s cubic-bezier(.3,.25,.15,1) infinite;
  animation:spin 1.5s cubic-bezier(.3,.25,.15,1) infinite;
}
@-moz-keyframes spin { 100% { -moz-transform: rotate(180deg); } }
@-webkit-keyframes spin { 100% { -webkit-transform: rotate(180deg); } }
@keyframes spin { 100% { -webkit-transform: rotate(180deg); transform:rotate(180deg); } }
</style>
